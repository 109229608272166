import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./InfoCard.module.sass"

export type InfoCardProps = {
  icon: React.ReactNode
  heading: React.ReactNode
  items: React.ReactNode
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, heading, items }) => {
  return (
    <div className={styles.root}>
      <span>
        {icon}
        {heading}
      </span>
      {items}
    </div>
  )
}

export default InfoCard
