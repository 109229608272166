import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import ExpertQuestions from "./Questions/Questions"

const QuestionsRoot: React.FC = () => (
  <Routes>
    <Route index element={<ExpertQuestions />} />
    <Route path="/:questionId" element={<ExpertQuestions />} />
    <Route path="/*" element={<Navigate to="/questions" replace />} />
    <Route path="/**" element={<Navigate to="/questions" replace />} />
  </Routes>
)

export default QuestionsRoot
