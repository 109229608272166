import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./SearchInfoBanner.module.sass"

const SearchInfoBanner = () => {
  return (
    <div className={styles.root}>
      <div className={styles.headings}>
        <Text variant="h4">
          <Icon name="search" />
          Results are limited to 10,000 documents
        </Text>
        <Text variant="body2">
          Only the first 10,000 results are accessible, Please use the search or
          filter down your results.
        </Text>
      </div>
    </div>
  )
}

export default SearchInfoBanner
