import React, { useState } from "react"
import debounce from "lodash/debounce"

interface Options {
  value?: string
  delay?: number
}

export type DebouncedReturnValue = {
  debouncedValue: string
  setDebounce: (newValue: string) => void
}

const DEBOUNCE_IN_MILLIS = 500

export const useDebounce = ({
  value = "",
  delay = DEBOUNCE_IN_MILLIS,
}: Options = {}): DebouncedReturnValue => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const setDebounce = React.useMemo(
    () => debounce((newValue: string) => setDebouncedValue(newValue), delay),
    [delay]
  )

  return { debouncedValue, setDebounce }
}

export default useDebounce
