import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"

import styles from "./MarkdownEditor.module.sass"

const DEFAULT_INFO_MESSAGE = `Once saved, this edited summary will be finalized as Experts Verified Citations for reference.`

interface MarkdownEditorProps {
  initialValue?: string
  className?: string
  infoMessage?: string
  onSave: (content: string) => Promise<void> | void
  onClose: () => void
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  onSave,
  onClose,
  infoMessage = DEFAULT_INFO_MESSAGE,
  initialValue,
  className,
}) => {
  const [value, setValue] = useState<string>(initialValue || "")
  const [isSaving, setIsSaving] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleSave = async () => {
    try {
      setIsSaving(true)
      await onSave(value)
    } finally {
      setIsSaving(false)
    }
  }

  const adjustTextAreaHeight = (target: HTMLTextAreaElement) => {
    const textArea = target
    textArea.style.height = "auto"
    textArea.style.height = `${textArea.scrollHeight}px`
  }

  useEffect(() => {
    if (textAreaRef.current) {
      adjustTextAreaHeight(textAreaRef.current)
    }
  }, [textAreaRef])

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.body}>
        <TextAreaInput
          value={value}
          onChange={(e) => {
            const textArea = e.target as HTMLTextAreaElement
            setValue(textArea.value)
            adjustTextAreaHeight(textArea)
          }}
          ref={textAreaRef}
          disabled={isSaving}
        />
      </div>

      <div className={styles.footer}>
        <Text className={styles.footerText}>
          <Text variant="body2">
            <Icon name="info" inline />
          </Text>

          <Text variant="caption2" color="text50Color" inline>
            {infoMessage}
          </Text>
        </Text>

        <div className={styles.buttonContainer}>
          <Button
            onClick={onClose}
            variant="outlined"
            className={styles.closeButton}
            size="small"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isSaving}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MarkdownEditor
