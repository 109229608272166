import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./VerifyButton.module.sass"

type VerifyButtonProps = {
  disabled?: boolean
  isVerified?: boolean
  loading?: boolean
  onClick?: React.MouseEventHandler
}

const VerifyButton: React.FC<VerifyButtonProps> = observer(
  ({ isVerified, onClick, loading = false, disabled = false }) => {
    return isVerified ? (
      <Text variant="h4" className={styles.alignCenter}>
        <Icon color="green" name="shield-check-fill" inline />{" "}
        <Text variant="h5" inline>
          Verified by you
        </Text>
      </Text>
    ) : (
      <Button
        onClick={onClick}
        before={<Icon name="shield-check" />}
        size="small"
        disabled={loading || disabled}
        variant="outlined"
        color="green"
      >
        Verify this answer
      </Button>
    )
  }
)

export default VerifyButton
