import React, { useState } from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { SyncJobInfo } from "@framework/types/content-manager"

import SynInfoItem from "./SynInfoItem"

import styles from "./SyncInfo.module.sass"

type SyncInfoProps = {
  jobs: SyncJobInfo[]
}

const SyncInfo: React.FC<SyncInfoProps> = ({ jobs }) => {
  const [hideJobs, setHideJobs] = useState<Record<string, boolean>>({})

  const handleHideJob = (jobId: string) => {
    setHideJobs((prevHideJobs) => ({
      ...prevHideJobs,
      [jobId]: true,
    }))
  }

  const filteredJobs = jobs.filter((currentJob) => !hideJobs[currentJob?.id])

  return (
    <>
      {filteredJobs.map((currentJob, index) => (
        <SynInfoItem
          handleHideJob={handleHideJob}
          job={currentJob}
          key={currentJob.id + currentJob.error}
        />
      ))}
    </>
  )
}

export default SyncInfo
