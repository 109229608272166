import { SimpleBaseUserData } from "@framework/types/user"

export const noteIsValuable = (text: string) =>
  Boolean(text && text !== "<p></p>")

export const getFullName = (userData: SimpleBaseUserData) => {
  return (
    `${userData.firstName ?? ""} ${userData.lastName ?? ""}`.trim() ||
    userData.name ||
    null
  )
}
