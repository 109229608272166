import React from "react"

import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import { useController, useStore } from "@store"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"

import styles from "./Question.module.sass"

export type QuestionProps = {
  question: string
}
const Question: React.FC<QuestionProps> = ({ question }) => {
  const {
    solutionsStore: { solution, appliedFilters },
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()
  const { avatar } = useActiveAvatar()
  const isSearching = searchFlowStore.isAnyLoading()

  const { factFinderSolutionController } = useController()

  const handleSearch = () => {
    if (solution?.id)
      factFinderSolutionController.search(
        question,
        avatar,
        solution.id,
        appliedFilters
      )
  }
  return (
    <div className={styles.root}>
      <span>{question}</span>
      <IconButton disabled={isSearching}>
        <Icon name="arrow-up" rotateAngle={90} onClick={handleSearch} />
      </IconButton>
    </div>
  )
}

export default Question
