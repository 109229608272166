import find from "lodash/find"
import identity from "lodash/identity"

import { randomWordsArray } from "@framework/constants/common"
import { Option, Primitive } from "@framework/types/utils"

import { initArray } from "./numberUtils"

export const transformAnswerText = (text: string): Option[] =>
  text.split("\n").map((text) => ({
    name: uniqueId(),
    value: text,
  }))

export const uniqueId = (prefix: string = "") =>
  `${prefix}_${Math.random().toString(36).substr(2, 9)}`

export const textToHash = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    /* eslint-disable no-bitwise */
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

export const stringArrayToOptions = <T extends string = string>(
  array: readonly T[],
  valueTransform: (item: T) => string = (item) => capitalizeFirstLetter(item),
  nameTransform: (item: T) => T = (item) => item
): Option<T>[] =>
  array.map((item) => ({
    name: nameTransform(item),
    value: valueTransform(item),
  }))

export const arrayToReactList = <T = any>(array: T[]): Option<string, T>[] =>
  array.map((item) => ({
    name: uniqueId().toString(),
    value: item,
  }))

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export function capitalizeEachFirstLetter(input: string): string {
  return input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const parseDataString = (data: string): string[][] => {
  try {
    if (!data) throw Error()
    return JSON.parse(data)
  } catch (error) {
    return [[]]
  }
}

export const getExtension = (fileName: string) =>
  fileName
    ?.match(/\.[0-9a-zA-Z]+$/i)?.[0]
    ?.replace(".", "")
    .toLowerCase()

export const randomText = (words: number) => {
  if (words <= 0) throw Error(`Can't generate ${words} words length text`)
  return initArray(
    words,
    (i) => randomWordsArray[i % randomWordsArray.length]
  ).join(" ")
}

export const queryFilter = (query: string) => (option: string) => {
  return option.toLowerCase().includes(query.toLowerCase())
}

export const filterByQuery = (options: string[], query: string) => {
  if (query === "") return options
  return options.filter(queryFilter(query))
}

export interface FindByQuery {
  <T extends string>(options: T[], query: string): T | undefined

  <T>(options: T[], query: string, mapCallback: (item: T) => string):
    | T
    | undefined
}

export const findByQuery: FindByQuery = <T>(
  options: T[],
  query: string,
  mapCallback: (item: T) => string = identity
) => {
  const q = query.toLowerCase()
  return find(options, (it) => mapCallback(it).toLowerCase() === q)
}

export const constructComplexUrlWithParams = (
  baseUrl: string,
  params: { [key: string]: Primitive }
) => {
  const urlParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (value != null) urlParams.append(key, value.toString())
  })

  return `${baseUrl}?${urlParams.toString()}`
}

export const pluralize = (word: string, many = false): string => {
  return many ? `${word}s` : word
}

export const camelCaseToWords = (text: string): string => {
  return text
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}

export const removeHyphens = (
  text: string,
  removeUnderscores: boolean = false
): string => {
  const pattern = removeUnderscores ? /[-_]/g : /-/g
  return text.replace(pattern, " ")
}

export const formatStringToJSON = (text: string): string => {
  let parsedJson: any = {}
  try {
    const jsonString = text || "{}"

    const correctedMetadataString = jsonString
      .replace(/'/g, '"')
      .replace(/\bNone\b/g, "null")
    parsedJson = JSON.parse(correctedMetadataString)
  } catch (e) {
    // do nothing
  }
  return parsedJson
}
