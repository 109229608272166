import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import * as yup from "yup"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import { Company } from "@framework/types/company"
import { useController, useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"

import ProductsSection from "./ProductsSection"
import FiltersSection from "./FiltersSection"
import FiltersSearchSection from "./FiltersSearchSection"
import CompanySelection from "./CompanySelection"

import styles from "./FilterSubForm.module.sass"

export const validationSchema = yup.object({
  filters: yup.array().required().default([]),
})

export type FormData = yup.InferType<typeof validationSchema>

export type FiltersSubFormProps = {}

const FiltersSubForm: React.FC<FiltersSubFormProps> = observer(() => {
  const formik = useFormikContext<FormData>()
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const { solutionsStore, contentManagerStore } = useStore()
  const { solutionsController, contentManagerController } = useController()
  const { loadingCompanies, companies } = contentManagerStore
  const { isFiltersLoading } = solutionsStore

  const cleanupFilters = () => {
    formik.setFieldValue("filters", [])
  }

  const onCompanySelect = (company: Company) => {
    solutionsStore.availableFilters = null
    solutionsController.updateSelectedCompany(company.id)
    solutionsController.loadFilters()
    setSelectedCompany(company)
  }

  useEffect(() => {
    contentManagerController.getCompanies()
  }, [])

  useEffect(() => {
    if (companies.length === 1) {
      setSelectedCompany(companies[0])
    }
  }, [companies])

  return (
    <div className={styles.root}>
      <Templates.Header
        offsetBottom="medium"
        left={<Text variant="h4">Filters</Text>}
        right={
          <Button size="tiny" variant="slim" onClick={cleanupFilters}>
            Clear All
          </Button>
        }
      />

      <FiltersSearchSection />

      <div className={styles.body}>
        <div className={styles.sidebarContainer}>
          {(loadingCompanies || isFiltersLoading) && <Loader size="small" />}

          {selectedCompany || (!loadingCompanies && !companies.length) ? (
            <ProductsSection
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
          ) : (
            companies.length > 1 && (
              <CompanySelection onCompanySelect={onCompanySelect} />
            )
          )}
        </div>

        <div className={styles.filtersContainer}>
          <FiltersSection />
        </div>
      </div>
    </div>
  )
})

export default FiltersSubForm
