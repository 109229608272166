import React, { useCallback, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"

import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { Company } from "@framework/types/company"
import { Product } from "@framework/types/product"
import mainRoutes from "@root/main.routes"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import SummaryEntityStore from "@store/search/summary-entity.store"

import InfoCard from "./InfoCard"
import LineItem from "./LineItem"
import Question from "./Question"

import styles from "./SidePanel.module.sass"

export type SidePanelProps = {
  isSearching: any
}

const SidePanel: React.FC<SidePanelProps> = observer(({ isSearching }) => {
  const navigate = useNavigate()
  const {
    factFinderSolutionStore: { searchFlowStore, highlights, loadingHighlights },
    solutionsStore,
  } = useStore()
  const isSmallScreen = useMediaQuery(AppMediaQueries.maxDesktop)
  const { solutions } = solutionsStore
  const { factFinderSolutionController } = useController()
  const [suggestedQuestions, setSuggestedQuestion] = useState<string[]>([])

  const fetchHighlights = useCallback(async () => {
    if (searchFlowStore?.flowSequence?.length) {
      const lastSearchId =
        searchFlowStore?.flowSequence?.[searchFlowStore.flowSequence.length - 1]
      const data = searchFlowStore.getById(lastSearchId)
      let rawAnswer = ""
      if (data.blocks.length > 0) {
        const searchSummary = (data.blocks[data.blocks.length - 1] as any)
          ?.searchSummary as SummaryEntityStore
        rawAnswer = searchSummary?.summary || ""
        setSuggestedQuestion(searchSummary?.suggestedQuestion || [])
      }
      if (rawAnswer) {
        await factFinderSolutionController.getQueryHighlights(rawAnswer)
      }
    }
  }, [searchFlowStore, factFinderSolutionController])

  useEffect(() => {
    if (!searchFlowStore?.flowSequence?.length) {
      setSuggestedQuestion([])
    }
  }, [searchFlowStore?.flowSequence?.length])

  useEffect(() => {
    if (!isSearching) {
      fetchHighlights()
    }
  }, [fetchHighlights, isSearching])

  const gotoAccountPlanning = useCallback(
    (company: Company) => {
      const solution = solutions?.find(
        (item) => item.name === "Account Planning"
      )
      if (solution) {
        solutionsStore.setSolution(solution)
        navigate(
          `${mainRoutes.solutionView(solution.workflowType)}?manufacturer=${
            company.name
          }`
        )
      }
    },
    [solutions, solutionsStore, navigate]
  )

  const gotoProductPositioning = useCallback(
    (product: Product) => {
      const solution = solutions?.find(
        (item) => item.name === "Product Positioning"
      )
      if (solution) {
        solutionsStore.setSolution(solution)
        navigate(
          `${mainRoutes.solutionView(solution.workflowType)}?productId=${
            product.id
          }`
        )
      }
    },
    [solutions, solutionsStore, navigate]
  )

  const productsData = useMemo(
    () => ({
      icon: <Icon name="box" />,
      heading: "Products",
      items: (
        <div className={styles.lineItemWrapper}>
          {loadingHighlights && (
            <Skeleton lineHeight={30} className={styles.skeletonLoader} />
          )}
          {highlights?.products?.map((product) => (
            <LineItem
              key={product.name}
              name={product.name}
              option="Product Positioning"
              onClick={() => gotoProductPositioning(product)}
            />
          ))}
        </div>
      ),
    }),
    [highlights, gotoProductPositioning, loadingHighlights]
  )

  const companiesData = useMemo(
    () => ({
      icon: <Icon name="stats-random" />,
      heading: "Accounts",
      items: (
        <div className={styles.lineItemWrapper}>
          {loadingHighlights && (
            <Skeleton lineHeight={30} className={styles.skeletonLoader} />
          )}
          {highlights?.companies?.map((company) => (
            <LineItem
              key={company.name}
              name={company.name}
              option="Account planning"
              info={company.url}
              onClick={() => gotoAccountPlanning(company)}
            />
          ))}
        </div>
      ),
    }),
    [highlights, gotoAccountPlanning, loadingHighlights]
  )

  const companiesAvailable = !!highlights?.companies?.length
  const productsAvailable = !!highlights?.products?.length

  return (
    <div
      className={clsx(styles.root, {
        [styles.fadeIn]:
          companiesAvailable ||
          productsAvailable ||
          !!suggestedQuestions?.length,
        [styles.moveRight]: !isSmallScreen,
      })}
    >
      <InfoCard
        icon={<Icon name="question-mark" />}
        heading="Suggested Questions"
        items={
          <div className={styles.lineItemWrapper}>
            {suggestedQuestions.map((question) => (
              <Question question={question} />
            ))}
          </div>
        }
      />
      {productsAvailable && <InfoCard {...productsData} />}
      {companiesAvailable && <InfoCard {...companiesData} />}
    </div>
  )
})

export default SidePanel
