import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"

import ExpertQuestionDetails from "../components/QuestionForm/ExpertQuestionDetails"
import QuestionList from "../components/QuestionList/QuestionList"
import Header from "./Header"

import styles from "./Questions.module.sass"

type ParamsType = {
  questionId?: string
}

interface QuestionsPageProps {}

const QuestionsPage: React.FC<QuestionsPageProps> = observer(() => {
  const { questionId } = useParams<ParamsType>()

  const navigate = useNavigate()

  const { knowledgeStore } = useStore()
  const {
    expertQuestions,
    isExpertQuestionsLoading,
    isUpdateExpertQuestionLoading,
    loadExpertQuestions,
  } = knowledgeStore

  const questionsList = React.useMemo(() => {
    return [
      ...(expertQuestions.waiting ?? []),
      ...(expertQuestions.answered ?? []),
    ]
  }, [expertQuestions])

  const handleQuestionChange = (questionId: string) => {
    navigate(mainRoutes.question(questionId), { replace: true })
  }

  React.useEffect(() => {
    loadExpertQuestions()
  }, [])

  React.useEffect(() => {
    if (
      !isExpertQuestionsLoading &&
      questionsList.length &&
      (!questionId || !questionsList.find(({ id }) => id === questionId))
    ) {
      handleQuestionChange(questionsList[0]?.id ?? "")
    }
  }, [questionsList, questionId, isExpertQuestionsLoading])

  const currentQuestion =
    questionsList.find(({ id }) => id === questionId) ?? null

  return (
    <MainLayout>
      <div className={styles.root}>
        <Header className={styles.header} />

        <div className={styles.body}>
          <div className={styles.left}>
            <ScrollableContainer className={styles.questionListContainer}>
              <QuestionList
                items={questionsList}
                activeQuestionId={questionId}
                onSelect={handleQuestionChange}
                emptyMessage="No questions found"
                className={styles.questionList}
                loading={isExpertQuestionsLoading}
              />
            </ScrollableContainer>
          </div>

          <div className={styles.right}>
            {isExpertQuestionsLoading || isUpdateExpertQuestionLoading ? (
              <Loader size="large" fluid />
            ) : currentQuestion ? (
              <ExpertQuestionDetails
                questionData={currentQuestion}
                key={currentQuestion.id}
              />
            ) : (
              <NotFound>No question selected</NotFound>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  )
})

export default QuestionsPage
