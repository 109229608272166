import React from "react"
import clsx from "clsx"

import { SyncJobInfo } from "@framework/types/content-manager"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"

import styles from "./SyncInfo.module.sass"

export type SynInfoItemProps = {
  job: SyncJobInfo
  handleHideJob: (jobId: string) => void
}

const SynInfoItem: React.FC<SynInfoItemProps> = ({ job, handleHideJob }) => {
  return (
    <div
      className={clsx(styles.root, { [styles.error]: job.status === "error" })}
      key={job.id + job.error}
    >
      <div>
        <div className={styles.info}>
          <div className={styles.iconWrapper}>
            <Icon
              name={job.status === "error" ? "info" : "upload-cloud"}
              className={styles.infoIcon}
            />
          </div>

          <div className={styles.headings}>
            {job.status === "error" ? (
              <>
                <Text variant="h3">
                  Connector {job?.name} - {job?.type} has reported an error
                </Text>
                <Text variant="body2" color="redColor">
                  {job.error || "Unknown error occurred during synchronization"}
                </Text>
              </>
            ) : (
              <>
                <Text variant="h3">
                  Syncing Content from {job?.name} {job?.type}
                </Text>
                <Text variant="body2">
                  We are currently syncing your Contents from {job?.name}{" "}
                  {job?.type} account. This process ensures easy retrieval and
                  AI-powered analysis of your content.
                </Text>
              </>
            )}
          </div>
        </div>
        {!!job?.statusInfo?.length && (
          <div className={styles.statusInfos}>
            {job.statusInfo?.map((item) => (
              <span>
                <Text color="text50Color">{item.key} :</Text>
                <Text color="greenColor">{item.value}</Text>
              </span>
            ))}
          </div>
        )}
      </div>
      <Button variant="text" size="tiny" onClick={() => handleHideJob(job?.id)}>
        <Icon name="cross" />
      </Button>
    </div>
  )
}

export default SynInfoItem
