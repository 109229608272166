import React from "react"
import clsx from "clsx"

import styles from "./EntityItem.module.sass"

interface EntityItemProps extends React.ComponentProps<"button"> {
  active?: boolean
  color?: "primary" | "secondary"
}

const EntityItem = React.forwardRef<HTMLButtonElement, EntityItemProps>(
  ({ active, children, className, color = "primary", ...rest }, ref) => {
    return (
      <button
        className={clsx(
          styles.root,
          styles[color],
          { [styles.active]: active },
          className
        )}
        type="button"
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

export default EntityItem
