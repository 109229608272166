import React, { useState } from "react"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

import styles from "./SidePanel.module.sass"

const LineItem: React.FC<{
  name: string
  info?: string
  onClick: () => void
  option: string
}> = ({ name, option, onClick, info }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.lineItem}>
      <Button
        variant="text"
        size="tiny"
        className={styles.lineItemHeading}
        onClick={() => setOpen(!open)}
        after={<Icon name="line-arrow-down" rotateAngle={open ? 180 : 0} />}
      >
        <div>
          {name}
          {info && <span>{info}</span>}
        </div>
      </Button>
      {open && (
        <Button
          variant="text"
          size="tiny"
          after={<Icon name="arrow-up" rotateAngle={90} />}
          className={styles.lineItemOption}
          onClick={onClick}
        >
          {option}
        </Button>
      )}
    </div>
  )
}

export default LineItem
