import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

export class SummaryEntityStore {
  // injections

  // constructor

  constructor(initial?: { loading: boolean }) {
    makeAutoObservable(this)

    this.isLoading = initial?.loading ?? false
    this.id = nanoid()
  }

  // state

  id: string

  summary: string | null = null

  confidence: number = 0

  highlights: string[] = []

  isLoading = false

  error: string | null = null

  showAttachmentTruncationWarning = false

  suggestedQuestion: string[] = []

  get isLoaded() {
    return this.summary != null
  }

  // actions
}

export default SummaryEntityStore
