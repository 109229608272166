import clsx from "clsx"
import React from "react"

import { ExpertQuestion } from "@framework/types/question"
import EntityItem from "@pages/search/components/ItemsCollection/EntityItem"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import QuestionItem from "../QuestionItem/QuestionItem"

import styles from "./QuestionList.module.sass"

interface QuestionListProps {
  items: ExpertQuestion[]
  className?: string
  activeQuestionId?: string
  emptyMessage?: string
  loading?: boolean
  onSelect?: (id: string) => void
}

const QuestionList: React.FC<QuestionListProps> = ({
  items = [],
  activeQuestionId,
  emptyMessage,
  className,
  loading = false,
  onSelect,
}) => (
  <div className={clsx(styles.root, className)}>
    {items.length ? (
      items.map((itemProps) => {
        const isActive = itemProps.id === activeQuestionId
        return (
          <EntityItem
            color="secondary"
            active={isActive}
            onClick={() => onSelect?.(itemProps.id)}
            key={itemProps.id}
          >
            <QuestionItem data={itemProps} />
          </EntityItem>
        )
      })
    ) : loading ? (
      <Skeleton
        lineHeight={150}
        minWidth={100}
        count={15}
        spacing={16}
        rounded
      />
    ) : (
      <div className={styles.message}>{emptyMessage}</div>
    )}
  </div>
)

export default QuestionList
