import { makeAutoObservable } from "mobx"

import { GetQueryHighlightsResponse } from "@services/search.service"

import SearchFlowStore from "../search-flow.store"

export class FactFinderSolutionStore {
  constructor() {
    makeAutoObservable(this)
  }

  searchFlowStore = new SearchFlowStore()

  highlights: GetQueryHighlightsResponse | null = null

  loadingHighlights: boolean = false

  error: string | null = null
}

export default FactFinderSolutionStore
