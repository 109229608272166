import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import clsx from "clsx"

import { SearchEntityContextProvider } from "@pages/search/SearchContext/SearchResultContext"
import AppControlContainer from "@pages/launch-solutions/SolutionTabsContainer/AppControlContainer"
import AnswerSection from "@pages/launch-solutions/SolutionTabsContainer/AnswerSection"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"
import SidebarPopup from "@components/modals/components/SidebarPopup"
import useResizeObserver from "@components/hooks/useResizeObserver"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import SearchWidget from "./components/SearchWidget/SearchWidget"
import FactFinderSearchPassagesSidebar from "./components/SourcePassagesSidebar"
import RecentActivity from "./components/RecentActivity"
import SidePanel from "./components/SidePanel/SidePanel"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const FactFinderPage: React.FC<Props> = observer(({ data }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(AppMediaQueries.maxTablet)
  const isSmallScreen = useMediaQuery(AppMediaQueries.wideDesktop)

  const { avatar } = useActiveAvatar()

  const { solutionsController } = useController()
  const {
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore,
  } = useStore()
  const { appliedFilters, solution, showRecentActivity } = solutionsStore

  const [isSearched, setIsSearched] = React.useState(false)

  const closeModal = () => navigate(location.state?.backTo ?? mainRoutes.home())

  const isSearching = searchFlowStore.isAnyLoading()

  const hideRecentActivity = () => {
    solutionsStore.showRecentActivity = false
  }

  React.useEffect(() => {
    if (solutionsController) {
      solutionsController.loadFilters()
    }
  }, [solutionsController])

  React.useEffect(() => {
    searchFlowStore?.reset()
    setIsSearched(false)
  }, [appliedFilters])

  React.useEffect(() => {
    solutionsController.applyFilters(solution?.filters ?? [])
  }, [solution])

  const autofocusLatest = React.useRef(true)
  const rootRef = React.useRef<HTMLDivElement | null>(null)
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const containerRect = useResizeObserver(containerRef)

  React.useEffect(() => {
    if (
      !autofocusLatest.current ||
      rootRef.current == null ||
      containerRect == null ||
      !isSearching
    )
      return

    rootRef.current.scrollTo({
      top: rootRef.current.scrollHeight - 100,
      behavior: "smooth",
    })
  }, [containerRect?.height])

  React.useEffect(() => {
    if (!isSearching && autofocusLatest.current) {
      rootRef.current?.scrollTo({
        top: rootRef.current.scrollHeight,
        behavior: "smooth",
      })
    }

    autofocusLatest.current = true
  }, [isSearching])

  if (!avatar) return null

  useEffect(() => {
    if (searchFlowStore) searchFlowStore.isFlowReset = false
  }, [])

  const moveDown =
    isSearched ||
    !!searchFlowStore.flowSequence.length ||
    searchFlowStore.isFlowReset

  return (
    <div className={styles.root}>
      <div
        className={styles.container}
        onWheel={(e) => {
          if (e.deltaY < 0) {
            autofocusLatest.current = false
            return
          }

          const node = e.currentTarget

          const actualBottom = Math.ceil(node.scrollTop + node.clientHeight)
          const bottom = node.scrollHeight

          autofocusLatest.current = Math.abs(actualBottom - bottom) < 10
        }}
        ref={rootRef}
      >
        <div className={styles.body} ref={containerRef}>
          {searchFlowStore.flowSequence.map((searchId) => {
            return (
              <SearchEntityContextProvider
                searchEntity={searchFlowStore.getById(searchId)}
                key={searchId}
              >
                <AnswerSection />
              </SearchEntityContextProvider>
            )
          })}
          {isMobile &&
            !!searchFlowStore.flowSequence.length &&
            !isSearching && (
              <div className={styles.sidePanel}>
                <SidePanel isSearching={isSearching} />
              </div>
            )}
          <AppControlContainer
            title={data.name}
            description={data.description}
            moveDown={moveDown}
          >
            <SearchWidget
              avatar={avatar}
              handleSearch={() => setIsSearched(true)}
              disabled={isSearching}
              moveDown={moveDown}
            />
          </AppControlContainer>

          {showRecentActivity && (
            <SidebarPopup
              open={showRecentActivity}
              onClose={hideRecentActivity}
            >
              <RecentActivity onClose={hideRecentActivity} />
            </SidebarPopup>
          )}

          <Routes>
            <Route
              path=":searchId/:blockId/:answerIndex"
              element={<FactFinderSearchPassagesSidebar onBack={closeModal} />}
            />
          </Routes>
        </div>
      </div>
      {!isMobile && (!!searchFlowStore.flowSequence.length || moveDown) && (
        <div
          className={clsx(styles.sidePanel, {
            [styles.moveRight]: !isSmallScreen,
          })}
        >
          <SidePanel isSearching={isSearching} />
        </div>
      )}
    </div>
  )
})

export default FactFinderPage
