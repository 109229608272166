import React from "react"
import { observer } from "mobx-react-lite"

import { ExpertQuestion } from "@framework/types/question"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store/index"
import Avatar from "@components/ui/Avatar/Avatar"
import { noteIsValuable } from "@pages/questions/utils"

import UserNotes from "../UserNotes/UserNotes"

import styles from "./QuestionCard.module.sass"

interface QuestionCardProps {
  data: ExpertQuestion
}

const QuestionCard: React.FC<QuestionCardProps> = observer(({ data }) => {
  const { avatarsStore } = useStore()

  const avatar = avatarsStore.getAvatarByName(data.channel)

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Avatar
          name={data.author.name}
          src={data.author.avatarUrl || ""}
          height={32}
          width={32}
        />

        <div className={styles.question}>
          <Text variant="h4">{data.question}</Text>
        </div>
      </div>

      {noteIsValuable(data.userNote) && (
        <div className={styles.body}>
          <UserNotes text={data.userNote} />
        </div>
      )}
    </div>
  )
})

export default QuestionCard
