import Markdown from "react-markdown"
import rehypeKatexPlugin from "rehype-katex"
import rehypeRaw from "rehype-raw"
import remarkMathPlugin from "remark-math"
import remarkGfm from "remark-gfm"
import { PluginOptions } from "react-markdown/lib/react-markdown"

export const remarkPlugins: PluginOptions["remarkPlugins"] = [
  remarkMathPlugin,
  remarkGfm,
]

export const rehypePlugins: PluginOptions["rehypePlugins"] = [
  [rehypeKatexPlugin, { output: "mathml" }],
  rehypeRaw,
]

export default Markdown
